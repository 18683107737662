import { render, staticRenderFns } from "./showLocatioin.vue?vue&type=template&id=2145584b&scoped=true"
import script from "./showLocatioin.vue?vue&type=script&lang=js"
export * from "./showLocatioin.vue?vue&type=script&lang=js"
import style0 from "./showLocatioin.vue?vue&type=style&index=0&id=2145584b&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2145584b",
  null
  
)

export default component.exports